import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProfileSetup from '../components/MyDairy/ProfileSetup';
import Dashboard from '../components/MyDairy/Dashboard';
import Customers from '../components/MyDairy/customers';
import MainHeader from '../components/Headers/MainHeader';
import { useEffect, useState } from 'react';
import SiderMenu from '../components/Headers/SliderMenu';
import rightIcon from '../assets/images/icons-right.png'
import LogOut from './LogOut';
import MyProfile from '../components/MyDairy/MyProfile';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from '../components/MyDairy/ResetPassword';
import Products from '../components/MyDairy/Products';
import NewProduct from '../components/MyDairy/NewProduct';
import ViewCustomers from '../components/MyDairy/ViewCustomer';
import AddCustomer from '../components/MyDairy/AddCustomer';
import BuyMilk from '../components/MyDairy/BuyMilk';
import CreditHistory from '../components/MyDairy/CreditHistory';
import DepositeHistory from '../components/MyDairy/DepositeHistory';
import AddCash from '../components/MyDairy/AddCash';
import SellProductToCustomer from '../components/MyDairy/SellProductToCustomer';
import SetMilkParameters from '../components/MyDairy/SetMilkParameters';
import AdminAction from '../components/AdminAction/AdminAction';
import CenterBilling from '../components/AdminAction/CenterBilling';
import Charts from '../components/AdminAction/Charts';
import ActualCenterBilling from '../components/AdminAction/ActualCenterBilling';
import ViewChart from '../components/AdminAction/ViewChart';
import Transactions from '../components/AdminAction/Transactions';
import DairyBills from '../components/AdminAction/DairyBills';
import Earnings from '../components/AdminAction/Earnings';
import EditBuyMilk from '../components/MyDairy/EditBuyMilk';
import { getToken } from '@firebase/messaging';
import messaging from '../firebase';
import MyPlan from '../components/MyDairy/MyPlan';
import { GetInfoMyDairy } from '../store/actions/MyDairyActions';
import { useNavigate } from 'react-router';
import { SetFCMTokenAction } from '../store/actions/AuthenticationActions';
import MembershipWarningPopup from '../components/MyDairy/MembershipWarningPopup';
import Support from '../components/Customer/Support';
import GradeTracker from '../components/MyDairy/GradeTracker';
import BuyMilkSort from '../components/MyDairy/BuyMilkSort';
import BuyOptions from '../components/MyDairy/BuyOptions';
import PdfFormatSelector from '../components/AdminAction/PdfFormatSelector';
import CustomerPermissions from '../components/AdminAction/CustomerPermissions';
import MilkTransactions from '../components/AdminAction/MilkTransactions';
import ProductTransactions from '../components/AdminAction/ProductTransactions';
import CashTransactions from '../components/AdminAction/CashTransactions';
import TakeCash from '../components/MyDairy/TakeCash';

function MyDairy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const profile = useSelector((state) => state.Authentication.profile || {});
  const fcmToken = localStorage.getItem('fcm_token');
  const [showPopup, setShowPopup] = useState(false);
  const [daysLeft, setDaysLeft] = useState(10);

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    dispatch(GetInfoMyDairy());

    if(profile.plan_details.expire_date) {
      const expireDate = new Date(profile.plan_details.expire_date);
      const currentDate = new Date();
      const timeDifference = expireDate.getTime() - currentDate.getTime();
      const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      setDaysLeft(daysLeft);
      if (daysLeft <= 5) {
        setShowPopup(true);
      }
    }
  }, [dispatch, profile.plan_details.expire_date]);

  useEffect(() => {
    if (!profile.plan_details.activated) {
      navigate('/myplan');
    }
  }, [navigate, profile.plan_details.activated]);

  useEffect(()=> {
    if(!fcmToken) {
      try {
        Notification.requestPermission()
        .then(() => {
          return getToken(messaging);
        }).then((token) => {
          localStorage.setItem("fcm_token", token);
          dispatch(SetFCMTokenAction({token: token}))
          console.log(token);
        }).catch((err) => {
          console.log("fcm-error", err);
        });
      } catch (error) {
        console.log("Error in Notification.requestPermission:", error);
      }
    }
  }, [fcmToken,dispatch])

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  return(
    <>
      <MainHeader/>
      {showPopup && (
        <MembershipWarningPopup daysLeft={daysLeft} onClose={closePopup} />
      )}
      {isMenuOpen && (
        <div className="overlay" style={{overflow: 'hidden'}}>
        </div>
      )}
      <img src={rightIcon} className='right-arrow' alt="Milk Dairy" onClick={handleMenuToggle} />
      <SiderMenu isOpen={isMenuOpen} onClose={()=> handleMenuToggle()} />
      <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/setup" element={<ProfileSetup />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/new" element={<NewProduct action={'new'} />} />
          <Route path="/product/edit/:id" element={<NewProduct action={'edit'} />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customer/new" element={<AddCustomer action={'new'} />} />
          <Route path="/customer/edit/:id" element={<AddCustomer action={'edit'} />} />
          <Route path="/customer/:id" element={<ViewCustomers />} />  
          <Route path="/buy-milk" element={<BuyMilk />} />
          <Route path="/buy-milk-sort" element={<BuyMilkSort />} />
          <Route path="/buy-milk/edit/:id" element={<BuyMilkSort action={'edit'} />} />
          <Route path="/account" element={<MyProfile />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/credit_history" element={<CreditHistory />} />
          <Route path="/cash/new" element={<AddCash action={'new'} />} />
          <Route path="/cash/edit/:id" element={<AddCash action={'edit'} />} />
          <Route path="/sell/new" element={<SellProductToCustomer action={'new'} />} />
          <Route path="/sell/edit/:id" element={<SellProductToCustomer action={'edit'} />} />
          <Route path="/deposite_history" element={<DepositeHistory />} />
          <Route path="/set-parameters" element={<BuyOptions />} />
          {/* <Route path="/set-parameters/edit" element={<SetMilkParameters edit = {true} />} /> */}
          <Route path="/admin-actions" element={<AdminAction />} />
          <Route path="/center-billing" element={<CenterBilling />} />
          <Route path="/actual-center-billing" element={<ActualCenterBilling action={'new'}/>} />
          <Route path="/actual-center-billing/edit/:id" element={<ActualCenterBilling action={'edit'}/>} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/transactions-milk" element={<MilkTransactions />} />
          <Route path="/transactions-milk-cash" element={<CashTransactions />} />
          <Route path="/transactions-milk-product" element={<ProductTransactions />} />
          <Route path="/dairy-bills" element={<DairyBills />} />
          <Route path="/earnings" element={<Earnings />} />
          <Route path="/myplan" element={<MyPlan />} />
          <Route path="/support" element={<Support />} />

          <Route path="/pdf-format" element={<PdfFormatSelector />} />
          <Route path="/customer-permissions" element={<CustomerPermissions />} />

          <Route path="/take-cash/new" element={<TakeCash action={'new'} />} />
          <Route path="/take-cash/edit/:id" element={<TakeCash action={'edit'} />} />

          <Route path="/charts" element={<Charts />} />
          <Route path="/chart/:id" element={<ViewChart />} />
          <Route path="/grade" element={<GradeTracker />} />
          <Route path="/logout" element={<LogOut />} />
      </Routes>
    </> 
  );
}

export default MyDairy;
