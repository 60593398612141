import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { OtpVerifiaction, SendOtp } from "../store/actions/AuthenticationActions";
import { VerificationDesign } from "../styles/Design";

function Verification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const [otp, setOtp] = useState(['', '', '', '']);
  const [resent, setResent] = useState(false);
  const inputRefs = useRef([]);
  const errors = useSelector((state) => state.Authentication.errors || {});
  const otpSended = useSelector((state) => state.Authentication.otp);
  
  useEffect(() => {
    if(otpSended === false){
      navigate('/forgot_password')
    }
  }, []);

  const handleInput = (e, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = e.target.value;
    setOtp(updatedOtp);

    if (e.target.value !== '') {
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      } else {
        handleOtpComplete(updatedOtp);
      }
    } else {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleOtpComplete = (otpValues) => {
    dispatch(OtpVerifiaction({data: { otp: otpValues.join('') }}, navigate))
    console.log('OTP is complete:', otpValues.join(''));
  };

  const ResentOtp = () => {
    dispatch(SendOtp(params))
    setResent(true)
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <VerificationDesign>
    <div className="box">
      <h1>Verification</h1>
      <div className="popup_content">
        <div className="input_contener">
          <label htmlFor="otp">Check Your email - <b>{params?.email}</b></label>
          <div className="otp-input-container">
            {otp.map((value, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(ref) => (inputRefs.current[index] = ref)}
              />
            ))}
          </div>
          <span className="error">{errors.otp}</span>
          <p className='resent' onClick={() => ResentOtp()}>Resend OTP Code?</p>
          {resent && <p className='resent_sms'>The OTP was sent successfully.</p>}
          {/* <button className="button" onClick={(e)=> handleOtpComplete(e)}>
            <b>Forgot Password</b>
          </button> */}
        </div>
      </div>
    </div>    
      </VerificationDesign>
  );
}

export default Verification;
