import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../Elements/CustomSelect";
import { useLocation, useNavigate } from "react-router";
import { BuyMilkSortDesign, SellProductToCustomerDesign } from "../../styles/MyDairy/Design";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CustomerListingAction, DepositeToCustomer, ProductListingAction, UpdateCustomerProduct } from "../../store/actions/MyDairyActions";
import DatePicker from 'react-datepicker';
import moment from "moment";

function SellProductToCustomer({action}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const customerIdRef = useRef(null);
  const params = location.state;
  const { t } = useTranslation();
  const errors = useSelector((state) => state.Authentication.errors || {});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [cId, setCId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [submited, setSubmited] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerBalance, setCustomerBalance] = useState('');
  const customers = useSelector((state) => state.MyDairy.customers || []);
  const products = useSelector((state) => state.MyDairy.products || []);
  const [formData, setFormData] = useState({
    customer_id: action === 'edit' ? params.customer.id : params?.customer?.id,
    product_id: action === 'edit' ? params.product?.id : '',
    quntity: action === 'edit' ? params.quntity : '',
    note: action === 'edit' ? params.note : '',
    deposit_type: "product",
    amount: '',
  });
  const [perltr, Setperltr] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const fetchCustomerDetails = (customerId) => {
    const customer = customers.find((customer) => customer.sid === parseInt(customerId));
    if (customer) {
      console.log(customer);
      setCId(customer.id);
      setCustomerBalance(customer.customer_account)
      setCustomerName(customer.name);
      setFormData({...formData, customer_id: customer.id})
    } else {
      setCustomerName('');
    }
  };

  useEffect(()=> {
    customerIdRef.current.focus();
    dispatch(ProductListingAction());
  }, [dispatch])

  useEffect(()=> {
		dispatch(CustomerListingAction());
  }, [dispatch, submited])

  useEffect(()=> {
    if(action === 'edit'){
      setCustomerId(params.customer.sid);
      fetchCustomerDetails(params.customer.sid);
      setSelectedProduct({value: params.product?.id, label: `${params.product?.name} - #${params.product?.sid}`, amount: params.product?.amount})
      Setperltr(params.product?.amount);
      setSelectedDate(new Date(params.date))
      setFormData({
        customer_id: params.customer.id,
        product_id: params.product?.id,
        quntity: params.quntity,
        deposit_type: "product",
        amount: params.amount,
        note: params.note,
        date: params.date,
      });
    } else {
      if(params?.customer) {
        setCustomerId(params.customer.sid);
        fetchCustomerDetails(params.customer.sid);
        setFormData({
          ...formData,
          customer_id: params.customer.id,
          deposit_type: "product",
        })
      }
    }
	}, [params, dispatch, action, customers])

  useEffect(()=> {
    const product = findProductById(formData.product_id);
    setFormData({ ...formData, amount: calculateAmount()});
    Setperltr(product?.amount);
	}, [formData.product_id, formData.quntity])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const formInputs = document.querySelectorAll('.input, select');
      const currentInputIndex = Array.from(formInputs).findIndex(input => input === document.activeElement);
      const nextInputIndex = currentInputIndex + 1;

      if (nextInputIndex < formInputs.length) {
        formInputs[nextInputIndex].focus();
      } else {
        if(formData.amount > 0) {
          handleSubmit(event);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(action === 'edit'){ 
      if(formData.product_id === undefined){
        toast.warning(t("Sorry! You can't update because product is not available"), {
        position: toast.POSITION.BOTTOM_LEFT,
        });
        return
      }
      dispatch(UpdateCustomerProduct({id: params.id, data: {...formData, date: moment(selectedDate).format('YYYY-MM-DD')}}, navigate, true, t, params.redirect)).then(()=> {
        setSubmited(!submited)
        setCId('');
        setCustomerName('');
        setCustomerId('');
      });
    } else {
      dispatch(DepositeToCustomer({data: {...formData, date: moment(selectedDate).format('YYYY-MM-DD')}}, setFormData, setCustomerId, true, 'product', setSelectedProduct, t)).then(()=> {
        setSubmited(!submited)
        customerIdRef.current.focus();
        setCId('');
        setCustomerName('');
        setCustomerId('');
      });
    }
  };

  const findProductById = (productId) => {
    return products.find(product => product.id === Number(productId));
  };

  const calculateAmount =()=> {
    const product = findProductById(formData.product_id);
    const { quntity } = formData;
    const productAmount = product?.amount;
    const calculatedAmount = Number(quntity) * Number(productAmount);
    return calculatedAmount.toFixed(2); 
  }

  return (
    <BuyMilkSortDesign>
      <div className="container">
        <h1 className="heading">{t('Sell Product')}</h1>
        {customerName && (
          <div className="row">
            <div className="col">
              <label className="label">{t('Customer Name')} :</label>
              <p className="text">{customerName}</p>
            </div>
            <div className="col">
              <label className="label">{t('Status')} :</label>
              <p className="text" style={{ color: customerBalance?.balance > 0 ? '#28728f' : '#B00020' }}>{customerBalance?.balance > 0 ? t("To give to the customer") : t("To take from the customer")}</p>
            </div>
            <div className="col">
              <label className="label">{t('Account Balance')} :</label>
              <p className="text" style={{ color: customerBalance?.balance > 0 ? '#28728f' : '#B00020' }}>{customerBalance?.balance}</p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <label className="label">{t('Date')} :</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd-MM-yyyy"
              className="datepicker"
            />
          </div>
          <div className="col">
            <label className="label">{t('Customer ID')} :</label>
            <input
              ref={customerIdRef}
              type="number"
              onKeyPress={handleKeyPress}
              autoFocus
              value={customerId}
              onChange={(e) => {
                setCustomerId(e.target.value);
                fetchCustomerDetails(e.target.value);
              }}
              className="input"
            />
          </div>
          <div className="col">
            <label className="label">{t("Select Product")} :</label>
            <select
              value={formData.product_id}
              onChange={handleInputChange}
              className="select"
              name="product_id"
            >
              <option value=''>{t('Select')}</option>
              {
                products.map((product) => (
                  <option value={product.id}>{`${product.name} (${product.amount})`}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="label">{t("Quantity")} {perltr ? <b>({perltr})</b> : ''}:</label>
            <input
              name="quntity"
              type="number"
              onKeyPress={handleKeyPress}
              autoFocus
              value={formData.quntity}
              className="input"
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <label className="label">{t('Amount')} :</label>
            <p className="text">{calculateAmount() > 0 ? calculateAmount() : 0}</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="label">{t("Note")} :</label>
            <input
              name="note"
              type="text"
              onKeyPress={handleKeyPress}
              autoFocus
              value={formData.note}
              className="input"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row" style={{justifyContent: 'center'}}>
          <button className={`submit-button ${formData.amount <= 0 ? 'disabled' : ''}`} onClick={(e) => formData.amount > 0 && handleSubmit(e)}>
            {t('Save')}
          </button>
        </div>
      </div>
    </BuyMilkSortDesign>
  );
}
export default SellProductToCustomer;
