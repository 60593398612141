// src/components/SideMenu.js
import React, { useEffect, useState } from 'react';
import { SliderMenuDesign } from '../../styles/Design';
import logoImage from '../../assets/images/MilkDairyLogo/Blue Fresh Milk Logo Template (1).png'
import homeIcon from '../../assets/images/icons-home.png'
import accountIcon from '../../assets/images/icons-account.png'
import supportIcon from '../../assets/images/icons-support.png'
import settingIcon from '../../assets/images/icons-setting.png'
import planIcon from '../../assets/images/icon-plan.png'
import logoutIcon from '../../assets/images/icons-logout.png'
import cashTake from '../../assets/images/cash-take.png'
import accessIcon from '../../assets/images/icons-access.png'
import passwordChangeIcon from '../../assets/images/icons-change-password.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SiderMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [showSettingsSubMenu, setShowSettingsSubMenu] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest('.side-menu')) {
        event.preventDefault(); 
        onClose();
      }
    };

    const handleBodyScroll = () => {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    };

    document.addEventListener('click', handleOutsideClick);
    handleBodyScroll();

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.body.style.overflow = 'visible';
    };
  }, [isOpen, onClose]);

  const navigateToPage=(page)=>{
    onClose();
    setShowSettingsSubMenu(false);
    navigate(page);
  };

  const toggleSettingsSubMenu = () => {
    setShowSettingsSubMenu((prev) => !prev);
  };

  return (
    <SliderMenuDesign>
      <div className={`side-menu ${isOpen ? 'open' : ''}`}>
        <div className="logo" onClick={onClose}>
            <img src={logoImage} alt="Milk Dairy" />
        </div>
        <ul>
          <div className='menu-name' onClick={() => navigateToPage('/')}>
            <img src={homeIcon} alt="Milk Dairy" />
            <li>{t("Home")}</li>
          </div>
          <div className='menu-name' onClick={() => navigateToPage('/account')}>
            <img src={accountIcon} alt="Milk Dairy" />
            <li>{t("Account")}</li>
          </div>

          <div className='menu-name' onClick={() => navigateToPage('/take-cash/new')}>
            <img src={cashTake} alt="Milk Dairy" />
            <li>{t("Accept Cash Payments")}</li>
          </div>

          <div className='menu-name' onClick={() => navigateToPage('/myplan')}>
            <img src={planIcon} alt="Milk Dairy"/>
            <li>{t("My Plan")}</li>
          </div>
 
          <div className='menu-name' onClick={() => toggleSettingsSubMenu()}>
            <img src={settingIcon} alt="Milk Dairy" />
            <li>{t("Settings")}</li>
          </div>

          {showSettingsSubMenu && (
            <ul className="sub-menu">
              <div className='menu-name' onClick={() => navigateToPage('/customer-permissions')}>
                <img src={accessIcon} alt="Milk Dairy"/>
                <li>{t("Permissions")}</li>
              </div>
              <div className='menu-name' onClick={() => navigateToPage('/reset-password')}>
                <img src={passwordChangeIcon} alt="Milk Dairy"/>
                <li>{t("Change Password")}</li>
              </div>
            </ul>
          )}

          <div className='menu-name' onClick={() => navigateToPage('/support')}>
            <img src={supportIcon} alt="Milk Dairy"/>
            <li>{t("Support")}</li>
          </div>
        </ul>
        <div className="logout" onClick={() => console.log('Logout clicked')}>
          <div className='menu-logut' onClick={() => navigateToPage('/logout')}>
            <img src={logoutIcon} alt="Milk Dairy" />
            <p>{t("Logout")}</p>
          </div>
        </div>
      </div>
    </SliderMenuDesign>
  );
};

export default SiderMenu;
