import styled from "styled-components";

export const ProfileSetupDesign = styled.div`
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 20px;
}

.box {
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  background-color: #a4c4d1;
  paddign: 20px;
}

input {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border-radius: 10px;
  border: 2px solid #28728f;
  box-sizing: border-box;
}

label, p {
  color: #fff;
}

h2 {
  color: #28728f;
}

button {
  background-color: #28728f;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #4fa8de;
}

.result {
  margin-top: 20px;
}

`;

const breakpoints = {
  medium: '768px',
};

const breakpoints1 = {
  small: '600px',
  medium: '900px',
  large: '1200px',
};

export const CustomerDesign = styled.div`
overflow: hidden;

h1 {
  margin-top: 35px;
  color: #28728f;
  text-align: center;
}

p {
  cursor: pointer;
  color: #28728f;
  font-style: italic;
  text-align: center;
}

input {
  border: 2px solid #28728f;
  width: 28%;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
  position: absolute;
  left: 36%;
}

.customer-Listing {
  margin-top: 75px;
  padding: 20px
}

.contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px 0px 20px;
}

.content-box {
  width: 80%;
  border: 4px solid #28728f;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    border: 4px solid #28728f;
    color: white;
    background: #a4c4d1;
  }
}

.member {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
}

.name {
  font-size: 25px;
  font-weight: bold;
}

.code {
  font-size: 15px;
  font-weight: bold;
}

.member2 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  flex: 100%;
  gap: 10px;
  flex-wrap: wrap;
  align-items: baseline;
}

.number {
  display: flex;
  justify-content: space-between;
  flex: 2;
  font-size: 15px;
  font-weight: bold;
}

.village {
  flex: 50%;
  font-size: 20px;
  font-weight: bold;
}

.circular-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #28728f;
  color: #fff;
  font-size: 18px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  font-size: xx-large;
}

.circular-button:hover {
  background-color: #a4c4d1;
}

@media (max-width: 500px) {
  input {
    width: 50%;
    left: 25%;
  }
}
`;

export const ViewCustomerDesign = styled.div`
overflow: hidden;
padding-bottom: 20px;

h2 {
  color: #28728f;
}

.name-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 20px;
  @media (max-width: 300px) {
    flex-direction: column;
  }
}

img {
  width: 10%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 80px;
  @media (max-width: 1000px) {
    width: 20%;
  }

  @media (max-width: 500px) {
    width: 30%;
  }

  @media (max-width: 300px) {
    width: 40%;
  }
}

hr {
  height: 1px;
  background-color: black;
  margin: 1em 0;
  width: 100%;
}

button {
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 5em;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
  background-color: #28728f;
}

.contents {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
  flex-wrap: wrap;
}

.box {
  flex: 1;
  padding: 20px;
  border: 2px solid #28728f;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  background-color: #28728f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.box h2 {
  color: #fff;
}

}
`;

export const DashboardDesign = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;

h1 {
  color: #28728f;
  font-size: 40px;
  text-align: center;
}

> div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 30px;
}

> div > div {
  width: 25vw;
  height: 28vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28728f;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #28728f;
  overflow: hidden;
  text-overflow: ellipsis;
}

> div > div > p {
  margin: 0px;
  font-size: larger;
}

> div > div:hover {
  background-color: #fff;
  color: #28728f;
  border: 2px solid #28728f;
}

@media (max-width: 768px) {
  h1 {
    font-size: 25px;
  }
  
  > div > div {
    padding: 10px; 
    width: 20vh; 
    height: 18vw; 
  }
}

@media (max-width: 620px) {
  > div > div {
    width: 16vh; 
    height: 16vw; 
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 20px;
  }

  > div > div {
    width: 12vh; 
    height: 15vw;
    font-size: small;
  }
}
`;

export const AddCustomerDesign = styled.div`
margin-bottom: 10px;
padding: 10px;
overflow: hidden;

.errors {
  text-align: center;
  display: contents;
  margin-left: 10px;
}

.contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.profile {
  width: 25%;
}

h1 {
  color: #28728f;
  display: flex;
  justify-content: center;
}

form {
  width: 100%; 
  max-width: 600px; 
  background-color: #a4c4d1;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}

img {
  width: 100%; 
  max-width: 150px; 
  padding: 10px;
  border-radius: 80px;
}

input {
  border: 2px solid #28728f;
  width: 100%; 
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
}

.password-input {
  display: flex;
  align-items: center;
  width: 48%;
}

.eye-buttton {
  margin-left: -35px;
}

label {
  margin-left: 15px;
}

.full-input {
  width: 40%
}

.profile-name-number {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.passwords {
  display: flex;
  gap: 20px;
}

.address {
  display: flex;
  gap: 30px;
  align-items: center;
}

.name-number {
  width: -webkit-fill-available;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: 160px;
  margin: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #fff;
}

select, input {
  border: 2px solid #28728f;
  width: 100%; 
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
}

.tab-group {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  margin-bottom: 10px;
}

.tab.active {
  background-color: #28728f;
  color: #fff
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.radio-group label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-group input {
  margin-right: 5px;
}

@media (max-width: 640px) {
  .profile-sub-section {
    flex-direction: column;
    gap: 10px;
  }

  .profile-name-number {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 440px) {
    .profile {
      width: 35%;
    }

    .password-input {
      width: 100%
    }

    .passwords {
      flex-direction: column;
    }
  }
`;

export const MyProfileDesign = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 45px;

.profile-container {
  padding: 20px;
  border: 1px solid #28728f;
  border-radius: 8px;
}

.profile-heding {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

h1 {
  margin-top: 35px;
}

h2 {
  margin-left: 30px;
}

h1, h2 {
  color: #28728f;
}

.profile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-sub-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 100px;
  width: 100%;
  justify-content: center;
}

.error {
  display: block;
  text-align: left;
  margin-left: 15px;
}

.profile_image {
  cursor: pointer;
  width: 150px;
  height: 150px;
  background-color: rgba(179, 179, 179, 1);
  border-radius: 50%;
  transition: all 0.1s linear;
}

.profile_image > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile_image:hover .upload-icon-overlay {
  opacity: 1;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: 40%;
  margin: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.profile-img-in {
  gap: 33%;
  align-items: center;
}

input {
  border: 2px solid #28728f;
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
}

label {
  margin-left: 15px;
}

.full-input {
  width: 40%
}

@media (max-width: 640px) {
  .full-input {
    width: 100%;
  }

  .profile-sub-section {
    flex-direction: column;
    gap: 10px;
  }

  h2 {
    margin-left: 10px;
  }
`;

export const ResetPasswordDesign = styled.div`
padding: 30px;

.contener {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
}

.box {
  background-color: #a4c4d1;
  border-radius: 30px;
  width: 80%;
  max-width: 500px;
}

form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: #28728f;
  text-align: center;
}

input {
  border: 2px solid #28728f;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
  width: 80%
}

.lable-input {
  width: 80%
}

.password-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eye-buttton {
  margin-left: -34px;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: 50%;
  margin: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.error {
  text-align: center;
  margin: 0px;
  padding: 0px 38px 0px 38px;
}

@media (max-width: 768px) {
  input {
    width: 100%;
  }
}

`;

export const ProductsDesign = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 45px;
overflow: hidden;

.product-container {
  padding: 20px;
  border: 1px solid #28728f;
  border-radius: 8px;
}

h1 {
  margin-top: 35px;
  color: #28728f;
}

.product-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

p {
  cursor: pointer;
  color: #28728f;
  font-style: italic;
  text-decoration: underline;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  margin: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.no-products {
  display: contents;
} 

`;

export const CustomTableDesign = styled.div`
display: contents;

table {
  display: inline-flex;
  flex-direction: column;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
  border: 2px solid #28728f;
  width: 80%;
  overflow: hidden;
}

thead {
  display: inline-table;
  width: 100%;
}

th, td {
  padding: 12px 40px 12px 40px;
  text-align: center;
  width: -webkit-fill-available;
}

th {
  background-color: #f2f2f2; 
}

tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

th, tr.white {
  background-color: #fff; 
}

tr.grey {
  background-color: #f2f2f2; /* Grey background for even rows */
}

th[colspan] {
  text-align: center;
  border: none;
  color: #fff;
}

tbody {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

tbody::-webkit-scrollbar {
  width: 5px;
}

.table-heading {
  background: #28728f;
  width: 100%
}

button {
  background-color: #fff;
  border: none;
  color: #28728f;
  padding: 12px;
  border-radius: 30px;
  width: 100px;
  cursor: pointer;
  margin-right: 10px;
}

.table-heading-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 250px) and (max-width: 550px) {
  padding: 45px 0px 0px 0px;
  th, td, tr {
    padding: 2px;
    text-align: center;
    font-size: 10px;
  }

  table {
    width: -webkit-fill-available;
  }

  .table-heading-items {
    height: 20px;
  }

  button {
    padding: 4px;
    font-size: 6px;
    width: fit-content;
  }
}

@media (min-width: 1500px) {
  tbody {
    max-height: 450px;
  }
}
`;

export const CustomCreditHistoryTableDesign = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 45px 0px 0px 0px;
overflow: hidden;

table {
  display: inline-flex;
  flex-direction: column;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
  border: 2px solid #28728f;
  width: 90%;
  overflow: hidden;
}

.scroll-tr {
  max-height: 300px;
  width: -webkit-fill-available;
  overflow: auto;
}

::-webkit-scrollbar-thumb {
  background: #a4c4d1; 
}

div::-webkit-scrollbar {
  width: 5px;
}

thead {
  display: inline-table;
  width: 100%;
}

.product-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

p {
  cursor: pointer;
  color: #28728f;
  font-style: italic;
  text-decoration: underline;
}

th, td, tr {
  padding: 12px 40px 12px 40px;
  text-align: center;
  width: -webkit-fill-available;
}

th {
  background-color: #f2f2f2; 
}

th, tr.white {
  background-color: #fff; 
}

tr.grey {
  background-color: #f2f2f2;
}

th[colspan] {
  text-align: center;
  border: none;
  color: #fff;
}

.scroll-tr tr {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

tbody {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

tbody::-webkit-scrollbar {
  width: 5px;
}

.table-heading {
  background: #28728f;
  width: 100%
}

button {
  background-color: #fff;
  border: none;
  color: #28728f;
  padding: 12px;
  border-radius: 30px;
  width: 110px;
  cursor: pointer;
  margin-right: 10px;
}

.table-heading-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 400px) {
  padding: 45px 0px 0px 0px;
  th, td, tr {
    padding: 2px;
    text-align: center;
    font-size: 6px;
  }

  table {
    width: -webkit-fill-available;
  }

  .table-heading-items {
    height: 20px;
  }

  button {
    padding: 4px;
    font-size: 6px;
    width: fit-content;
  }
}

@media (min-width: 400px) and (max-width: 525px)  {
  padding: 45px 0px 0px 0px;
  th, td, tr {
    padding: 12px 10px 6px 4px;
    text-align: center;
    font-size: 9px;
  }

  table {
    width: -webkit-fill-available;
  }

  .table-heading-items {
    height: 20px;
  }

  button {
    padding: 2px;
    font-size: 13px;
  }
}

@media (min-width: 525px) and (max-width: 800px)  {
  th, td, tr {
    padding: 12px 10px 6px 4px;
    text-align: center;
    font-size: 10px;
  }

  table {
    width: -webkit-fill-available;
  }

  .table-heading-items {
    height: 60px;
  }

  button {
    padding: 13px;
    font-size: 10px;
  }
}

@media (min-width: 800px) and (max-width: 1280px)  {
  th, td, tr {
      padding: 12px 10px 6px 4px;
      text-align: center;
      font-size: 12px;
    }
  
    .table-heading-items {
      height: 90px;
    }
  
    button {
      padding: 15px;
      font-size: 12px;
    }
  }
}
`;

export const NewProductsDesign = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 45px;
overflow: hidden;

h1 {
  margin-top: 35px;
  color: #28728f;
  text-align: center;
}

input {
  border: 2px solid #28728f;
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
}

label {
  margin-left: 15px;
}

.full-input {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: 40%;
  margin: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.box {
  background: #a4c4d1;
  padding: 20px;
  border: 1px solid #28728f;
  border-radius: 8px;
  width: 310px;
}

.profile-sub-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  justify-content: center;
}

.error {
  margin: -6px 0px 10px 12px;
}

@media (max-width: 640px) {
    .box, .full-input {
      width: auto;
    }
  }

.profile-name-number {
  margin-top: 20px;
  display: flex;
  flex-direction: row; 
  align-items: center;
  gap: 15px;
}

.password-address {
  display: flex;
  flex-direction: column; 
  gap: 20px;
}

.passwords,
.address {
  display: flex;
  flex-direction: column; 
  gap: 10px;
}

.address input {
  width: 100%; 
}

button {
  width: 100%;
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 5em;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
  background-color: #28728f;
}

@media (min-width: 768px) {
  form {
    width: 50%;
  }

  img {
    max-width: 200px;
  }

  .name-number {
    flex-direction: row; 
    justify-content: space-between;
  }

  .password-address {
    display: flex;
    flex-direction: column; 
    gap: 20px;
  }

  .passwords,
  .address {
    display: flex;
    flex-direction: row;
    width: 100%; 
  }

  .address input {
    width: 50%; 
  }

  button {
    width: 68%;
  }
}

`;

export const BuyMilkDesign = styled.div`
height: 100%;
padding: 40px;

h1 {
  margin-top: -35px;
  color: #28728f;
  text-align: center;
}

h5 {
  text-align: center;
  color: red  ;
}

h3 {
  color: #4fa8de;
  text-align: center;
}

.form-contener {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input {
  border: 2px solid #28728f;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0;
  box-sizing: border-box;
  border-radius: 30px;
}

.top-leval.elements {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.select,
  .datepicker {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    font-size: 14px;
    border-radius: 10px;
    border: 2px solid #28728f;
    box-sizing: border-box;
  }

b {
  color: #28728f;
}

.contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  gap: 30px;
  @media (max-width: 890px) {
    justify-content: space-around;
    gap: 0px;
  }
}

.content1,
.content2 {
  width: 47%;
}

label {
  padding-left: 25px;
}

form {
  padding: 20px;
  border: 2px solid #28728f;
  border-radius: 20px;
}

#milk-amount {
  background-color: #a4c4d1;
}

.edit-button {
  display: flex;
  margin: -40px -20px 20px 0px;
  align-items: center;
  justify-content: space-around;
}

.edit-button {
  color: #4fa8de;
  font-weight: bold;
}

.edit-button button{
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
  background-color: #28728f;
  right: 10px;
}

.save-button button {
  display: flex;
  justify-content: center;
  width: 150px;
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  background-color: #28728f;
}

.save-button button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button {
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  text-align: center;
}

.popup-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #28728f;
}

.popup-content p {
  margin-bottom: 10px;
}

.popup-content .duplicate-list {
  overflow-x: auto;
}

.popup-content .duplicate-list table {
  width: 100%;
  border-collapse: collapse;
}

.popup-content .duplicate-list th,
.popup-content .duplicate-list td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.popup-content .duplicate-list th {
  background-color: #28728f;
  color: #fff;
}

.popup-content .duplicate-list tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.popup-content .button-container {
  text-align: center;
  margin-top: 20px;
}

.popup-content button {
  margin: 0 10px;
  padding: 8px 16px;
  background-color: #28728f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #5fb1d2;
}

@media screen and (max-width: 820px) {
  .css-17z0cbu-control {
    width: 90%;
    padding: 0px;
    border-radius: 10px;
  }

  .css-1mo8iv9-control {
    width: 90%;
    padding: 0px;
    border-radius: 10px;
  }

  .select,
  .datepicker {
    padding: 10px;
    border-radius: 10px;
  }

  input {
    width: 90%;
    padding: 10px;
    border-radius: 10px;
  }

  .save-button button {
    width: 100px;
    max-width: 300px;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    font-size: 12px;
  }
  label {
    padding: 5px;
  }

  .top-leval.elements {
    gap: 5px;
  }
}

@media screen and (max-width: 450px) {
  font-size: 12px;
  label {
    padding: 2px;
  }
}

@media screen and (max-width: 350px) {
  .content1,
  .content2 {
    width: 100%;
  }
}
`;

export const AddCashDesign = styled.div`
height: 88.2vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
oveflow: hidden;

h1 {
  color: #28728f;
  text-align: center;
}

.form-contener {
  padding: 40px;
}

input {
  border: 2px solid #28728f;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0;
  box-sizing: border-box;
  border-radius: 30px;
}

b {
  color: #28728f;
}

.contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content1,
.content2 {
  width: 100%;
}

label {
  padding-left: 25px;
}

form {
  padding: 20px;
  border: 2px solid #28728f;
  border-radius: 20px;
}

button {
  display: flex;
  justify-content: center;
  width: 150px;
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  background-color: #28728f;
  cursor: pointer;
}
`;

export const SellProductToCustomerDesign = styled.div`
height: 100%;
padding: 40px;

h1 {
  margin-top: -35px;
  color: #28728f;
  text-align: center;
}

.form-contener {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input {
  border: 2px solid #28728f;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0;
  box-sizing: border-box;
  border-radius: 30px;
}

b {
  color: #28728f;
}

.contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  @media (max-width: 890px) {
    justify-content: space-around;
    gap: 0px;
  }
}

.content1,
.content2 {
  width: 100%;

  @media (min-width: 768px) {
    width: 47%;
  }

  @media (min-width: 1024px) {
    width: 47%;
  }
}

label {
  padding-left: 25px;
}

form {
  padding: 20px;
  border: 2px solid #28728f;
  border-radius: 20px;
}
#milk-amount {
  background-color: #a4c4d1;
}

.edit-button {
  display: grid;
  justify-content: end;
  margin: -40px -20px 20px 0px;
}

.edit-button button{
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
  background-color: #28728f;
  right: 10px;
}

.save-button button {
  display: flex;
  justify-content: center;
  width: 150px;
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  background-color: #28728f;
}

button {
  cursor: pointer;
}
`;

export const SetMilkParametersDesign = styled.div`

h1 {
  color: #28728f;
  text-align: center;
}

.page-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.form-container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #28728f;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #fff;
}

select, input {
  border: 2px solid #28728f;
  width: 100%; 
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
}

.tab-group {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  margin-bottom: 10px;
}

.tab.active {
  background-color: #28728f;
  color: #fff
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.radio-group label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-group input {
  margin-right: 5px;
}

.div-button {
  display: flex;
  justify-content: center;
}

button {
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 5em;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
  background-color: #28728f;
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px;
  }

  .tab {
    flex: 1 0 50%;
  }
}

@media (max-width: 400px) {
  .tab {
    flex: 1 0 100%;
  }
}
`;

export const MyPlanDesign = styled.div`
.my-plan-container {
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
  color: #28728f
}

.activated-plan {
  background-color: #28728f;
  color: white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.activated-plan h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.plans-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.plan {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

.plan:hover {
  transform: scale(1.05);
}

.recommended-badge {
  background-color: #4fa8de;
  color: #ffffff;
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.plan-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h3 {
  color: #28728f;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.duration {
  color: #4fa8de;
}

.price {
  color: #28728f;
  font-size: 2.5rem;
  margin: 20px 0;
  font-weight: bold;
}

.cta-button {
  background-color: #28728f;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #4fa8de;
}

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }

  .close {
    display: inline-block;
    padding: 10px 20px;
    background-color: #28728f;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
  }

  a {
    color: #28728f;
    text-decoration: auto;
  }

  .popup h2 {
    color: #28728f;
  }

  .logout-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
  }
`;

export const NotificationDesign = styled.div`
  .side-menu {
    position: fixed;
    z-index: 1;
    top: 0;
    left: -500px;
    height: 100%;
    width: 370px;
    background-color: #fff;
    transition: left 0.3s ease-in-out;
    overflow: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    @media (max-width: 400px) {
      width: 70%;
      left: -100%;
    }
  }

  .side-menu.open {
    left: 0;
    width: 380px;
    @media (max-width: 400px) {
      width: 80%;
    }
  }

  h2 {
    padding-top: 10px;
    color: #28728f;
    text-align: center;
    font-size: 24px;
  }

  .top hr {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid #ddd;
  }

  .container-notify {
    margin-top: -10px;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }

    &.read {
      background-color: #fffff;
    }

    &.unread {
      background-color: #c1d2dc;
    }
  }

  .data {
    display: flex;
    gap: 20px;
    padding-left: 7px;
    align-items: center;

    .title-body {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }

    .body {
      font-size: 14px;
      color: #555;
    }

    .time {
      margin-top: 5px;
      font-size: 12px;
      color: #777;
    }
  }

  p {
    text-align: center;
    color: #777;
    margin-top: 20px;
  }
`;

export const MembershipWarningPopupDesign = styled.div`
.membership-warning-popup {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 15px;
  background-color: #f44336;
  color: #fff;
  text-align: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  right: 50px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.membership-warning-popup p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}
`;

export const NotificationModalDesign = styled.div`
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: center;
}

.modal-header h2 {
  margin: 0;
}

.modal-content {
  margin-bottom: 20px;
}

.modal-close {
  background-color: #28728f;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.parameter-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.parameter-table th,
.parameter-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.parameter-table th {
  font-weight: bold;
  border: 1px solid #28728f;
  background-color: #28728f;
  color: #fff;
}

@media (max-width: 768px) {
  .modal-container {
    width: 90%;
    max-width: 90%;
  }
}
`;

export const BuyMilkSortDesign = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  // background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px #28728f;

  .container {
    text-align: center;
  }

  .heading {
    font-size: 24px;
    color: #28728f;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .col {
    flex: 1;
    margin-right: 10px;
  }

  .label {
    font-weight: bold;
    display: block;
    color: #666;
    font-size: 14px;
    text-align: left;
  }

  .react-datepicker-wrapper {
    display: block;
  }


  .input,
  .select,
  .datepicker {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    font-size: 14px;
    border-radius: 10px;
    border: 2px solid #28728f;
    box-sizing: border-box;
  }

  .text {
    margin-top: 8px;
    color: #333;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }

  .submit-button {
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    background-color: #28728f;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .submit-button:hover {
    background-color: #5fb1d2;
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  h5 {
    text-align: center;
    color: red  ;
  }

  b {
    color: #28728f;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    text-align: center;
  }
  
  .popup-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #28728f;
  }
  
  .popup-content p {
    margin-bottom: 10px;
  }
  
  .popup-content .duplicate-list {
    overflow-x: auto;
  }
  
  .popup-content .duplicate-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .popup-content .duplicate-list th,
  .popup-content .duplicate-list td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .popup-content .duplicate-list th {
    background-color: #28728f;
    color: #fff;
  }
  
  .popup-content .duplicate-list tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .popup-content .button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .popup-content button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #28728f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:focus {
      border: 4px solid #28728f;
      transition: border-color 0.3s ease-in-out;
    }
  }
  
  .popup-content button:hover {
    background-color: #5fb1d2;
  }  

  @media screen and (max-width: 360px) {
    .heading {
      font-size: 20px;
    }

    .label,
    .input,
    .select,
    .datepicker,
    .text {
      font-size: 10px;
      padding: 2px;
    }

    .submit-button {
      font-size: 10px;
      padding: 2px 14px;
    }
  }
`;

export const BuyOptionsDesign = styled.div`
  .buy-options-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .buy-options-title {
    color: #28728f;
    margin-bottom: 20px;
    text-align: center;
  }

  .buy-options-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .buy-options-button {
    width: 100%;
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    background-color: #28728f;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .buy-options-button:hover {
    background-color: #1c5c72;
  }

  @media screen and (max-width: 480px) {
    .buy-options-container {
      padding: 10px;
    }

    .buy-options-button {
      padding: 10px 15px;
      font-size: 0.9rem;
    }
  }
`;

export const CustomerPermissionsDesign = styled.div`
h1, h3 {
  text-align: center;
  color: #28728f;;
}

.customer-permissions {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.permission-category {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.permission-category h3 {
  margin-bottom: 10px;
}

.toggle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.toggle label {
  margin-right: 10px;
  position: relative;
}

.toggle input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.slider {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle input:checked + .slider {
  background-color: #28728f;
}

.toggle input:checked + .slider:before {
  transform: translateX(25px);
}
`;
