// src/components/SideMenu.js
import React, { useEffect, useState } from 'react';
import { NotificationDesign } from '../../styles/MyDairy/Design';
import { useDispatch, useSelector } from 'react-redux';
import { GetNotification, MarkReadNotification } from '../../store/actions/MyDairyActions';
import { TimeAgo } from './Timeago';
import { useNavigate } from 'react-router-dom';
import { FaCircle, FaPencilAlt, FaTrashAlt } from 'react-icons/fa'; 
import NotificationModal from './NotificationModal';

const Notification = ({isOpen, onClose}) => {
  const getnotification = useSelector((state) => state.MyDairy.getnotification || {});
  const [data, setData] = useState([]);
  const [preData, setPreData] = useState([]);
  const [pagy, setPagy] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const customer = useSelector((state) => state.MyDairy.customer || {});
  const [selectedNotification, setSelectedNotification] = useState(null);
  const dispatch = useDispatch();

  useEffect(()=> {
		if(isOpen) {
      setPreData(data);
      dispatch(GetNotification({page: currentPage}));
    }
    else {
      setData([]);
      setPreData([]);
      setCurrentPage(1);
    }
	}, [isOpen, currentPage])

  useEffect(() => {
    if(getnotification.data){
      setData([...preData, ...getnotification.data])
      setPagy(getnotification.pagination)
    } else {
      setData([])
      setPagy({})
    }
  }, [getnotification]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && selectedNotification === null && !event.target.closest('.side-menu')) {
        event.preventDefault(); 
        onClose();
      }
    };

    const handleBodyScroll = () => {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    };

    document.addEventListener('click', handleOutsideClick);
    handleBodyScroll();

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.body.style.overflow = 'visible';
    };
  }, [isOpen, onClose, selectedNotification]);

  const str = String(customer.name)
    .split(' ')
    .map(word => word.charAt(0))
    .join('');

  const handleNotification = async (e, notification, index) => {
    if(!notification.is_read){
      const updatedData = [...data];
      updatedData[index].is_read = true;
      dispatch(MarkReadNotification(notification.id)).then(()=> {
        setData(updatedData);
      })
    }
    if(notification.notify_type === "edit_sold_milk" || notification.notify_type === "delete_sold_milk") {
      setSelectedNotification(notification);
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'create_sold_milk':
        return <FaCircle color="#00cc00" />;
      case 'edit_sold_milk':
        return <FaPencilAlt color="#ff9900" />;
      case 'delete_sold_milk':
        return <FaTrashAlt color="#ff0000" />;
      default:
        return null;
    }
  };

  const handleScroll = () => {
    const scrolablePage = document.querySelector(".side-menu.open");
    const scrollTop = scrolablePage.scrollTop;
    const scrollHeight = scrolablePage.scrollHeight;
    const clientHeight = scrolablePage.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (!spinnerLoader && scrollPercentage >= 99 && pagy?.pages > currentPage && pagy?.next) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const scrolablePage = document.querySelector(".side-menu.open");
    scrolablePage?.addEventListener("scroll", handleScroll);

    return () => {
      scrolablePage?.removeEventListener("scroll", handleScroll);
    };
  }, [pagy?.pages > currentPage && pagy?.next, currentPage, spinnerLoader, isOpen]);
  
  return (
    <NotificationDesign>
      <div className={`side-menu ${isOpen ? 'open' : ''}`}>
        <h2>Notification</h2>
        <hr className='top'/>
        {data.length ? 
        data.map((notification, index) => (
          <div className={`container-notify ${notification.is_read ? 'read' : 'unread'}`} onClick={(e) => handleNotification(e, notification, index)} >
          <div key={notification.id} className='data'> 
            <div className='title-body'>
              <div className='title'>
                {getStatusIcon(notification.notify_type)} {notification.title}
              </div>
              <div>
                <div className='body'>
                  {notification.body}
                </div>
                <div className='time'>
                  <TimeAgo createdAt={notification.created_at} />
                </div>
              </div>
            </div>
          </div>
          <hr/>
          </div>
        )) :  
        <p>There is no notifications</p>
        }
        {data.length ? spinnerLoader && <div className="table-spinner-container" style={{height: 'auto'}}>
        <div className="table-spinner"></div>
      </div> : ''}
      </div>
      {selectedNotification && (
        <NotificationModal
          notification={selectedNotification}
          onClose={() => setSelectedNotification(null)}
        />
      )}
      </NotificationDesign>
  );
};

export default Notification
