import { useSelector } from "react-redux";
import { DashboardDesign } from "../../styles/MyDairy/Design";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const profile = useSelector((state) => state.Authentication.profile || {});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const functinalities = [
    {
      id: 1,
      name: 'Customers',
      navigate: '/customers'
    },
    {
      id: 2,
      name: 'Buy Milk',
      navigate: '/set-parameters'
    },
    {
      id: 3,
      name: 'Sell Product',
      navigate: '/sell/new'
    },
    {
      id: 4,
      name: 'Products',
      navigate: '/products'
    },
    {
      id: 5,
      name: 'Admin Actions',
      navigate: '/admin-actions'
    },
    {
      id: 6,
      name: 'Customer Bill',
      navigate: '/pdf-format'
    },
  ]

  return (
    <DashboardDesign>
      <h1> {profile.dairy_name}</h1>
      <div>
        {functinalities.map((el) => (
          <div
            key={el.id}
            onClick={() => navigate(el.navigate)}
          >
            <h3>{t(el.name)}</h3>
          </div>
        ))}
      </div>
    </DashboardDesign>
  );
}
export default Dashboard;
