import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DepositeHistoryAction, DestroyDepositAction } from '../../store/actions/MyDairyActions';
import { useNavigate } from 'react-router-dom';
import CustomCreditHistoryTable from './CustomCreditHistoryTable';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../AdminAction/Transactions';

function DepositeHistory({customer_id, customer}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const role = useSelector((state) => state.Authentication.role);
  const [currentPage, setCurrentPage] = useState(1);
  const [perform, setPerform] = useState("");
  const depositeDate = useSelector((state) => state.MyDairy.depositehistory.data || []);
  const [depositehistory, setDepositehistory] = useState([]);
  const [preDepositehistory, setPreDepositehistory] = useState([]);
  const pagy = useSelector((state) => state.MyDairy.depositehistory.pagination || {});

  useEffect(()=> {
    setPreDepositehistory(depositehistory);
    dispatch(DepositeHistoryAction({page: currentPage, customer_id: customer_id}, depositehistory));
  }, [perform, currentPage])

  useEffect(() => {
    if(depositeDate){
      setDepositehistory([...preDepositehistory, ...depositeDate])
    }
  }, [depositeDate]);

  const performNavigation =(url, data)=> {
    navigate(url, { state: data })
  }

  const DeleteDeposite=(event, id)=>{
    event.stopPropagation(); // Stop event propagation
    Swal.fire({
      title: t("Are you sure you want to delete this Deposit?"),
      text: t("This action is irreversible and the Deposit data will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DestroyDepositAction(id, true, setPerform, setDepositehistory, t));
      } else {
        return;
      }
    });
  };
  
  const data = depositehistory.length && depositehistory.map(item => {
    const { product, date, amount, deposit_type, quntity, note } = item;
    return role === 'MyDairy' ? {
      [t('Product')]: deposit_type === 'cash' ? t('Cash') : `${product?.name ? product.name : t('Not Available')} (${quntity})`,
      [t('Date')]: formatDate(date),
      [t('Amount')]: amount,
      [t('Note')]: note,
      [t('Action')]: <div className='product-actions'><p onClick={() => deposit_type === 'cash' ? performNavigation(`/cash/edit/${item.id}`, item) : performNavigation(`/sell/edit/${item.id}`, item)}>Edit</p><p onClick={(e) => DeleteDeposite(e, item.id)}>Delete</p></div>
    } :
    {
      [t('Product')]: deposit_type === 'cash' ? 'Cash' : `${product?.name ? product.name : t('Not Available')} (${quntity})`,
      [t('Date')]: formatDate(date),
      [t('Amount')]: amount,
    }
  });

  const handleScroll = () => {
    const scrolablePage = document.querySelector(".deposit .scroll-tr");
    const scrollTop = scrolablePage.scrollTop;
    const scrollHeight = scrolablePage.scrollHeight;
    const clientHeight = scrolablePage.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (!spinnerLoader && scrollPercentage >= 99 && pagy?.pages > currentPage && pagy?.next) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const scrolablePage = document.querySelector(".deposit .scroll-tr");
    scrolablePage?.addEventListener("scroll", handleScroll);

    return () => {
      scrolablePage?.removeEventListener("scroll", handleScroll);
    };
  }, [pagy?.pages > currentPage && pagy?.next, currentPage, spinnerLoader]);


  return (
    <>
      {data.length ? 
      <div className='deposit'>
        <CustomCreditHistoryTable data={data} tableName={t('Deposite History')} buttons={role === 'MyDairy' && <div className='buttons'><button onClick={()=> navigate('/sell/new', { state: {customer: customer} })}>{t("Sell Product")}</button><button onClick={()=> navigate('/cash/new', { state: {customer: customer} })}>{t("Add Cash")}</button></div>}/>
        </div> :
        role === 'MyDairy' && <div className='no-products'>
          <p>{t("There is no Deposit History yet please add new one by clicking below")}</p>
          <div style={{display: "flex", gap: "5px"}}>
            <button onClick={()=> navigate('/cash/new', { state: {customer: customer} })}>{t("Add Cash")}</button>
            <button onClick={()=> navigate('/sell/new', { state: {customer: customer} })}>{t("Sell Product")}</button>
          </div>
        </div>}
    </>
  )
}

export default DepositeHistory;
