import React, { useEffect, useRef, useState } from 'react';
import { ViewChartDesign } from '../AdminAction/Design';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateGrateRate, GradeListingAction, UpdateGradeRate } from '../../store/actions/MyDairyActions';

const GradeTracker = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const grades = useSelector((state) => state.MyDairy.grades || {});

  const [data, setData] = useState([
    { id: 1, name: 'Grade A', rate: 90 },
    { id: 2, name: 'Grade B', rate: 80 },
  ]);

  useEffect(() => {
    dispatch(GradeListingAction())
  }, [dispatch]);

  useEffect(() => {
    setData(grades);
  }, [grades]);

  const handleAddRow = () => {
    const newRow = { name: '', rate: '' };
    setData([...data, newRow]);
  };

  const handleCellEdit = (rowIndex, columnName, value) => {
    const updatedData = [...data];
    if(updatedData[rowIndex][columnName] !== value) {
      updatedData[rowIndex][columnName] = value;
      const editedRow = updatedData[rowIndex]
      setData(updatedData);

      if(editedRow.rate){
        if(editedRow.id) {
          dispatch(UpdateGradeRate({ data: editedRow }))
        } else {
          dispatch(CreateGrateRate({ data: editedRow }, data, rowIndex))
        }
      }
    }
  };

  return (
    <ViewChartDesign>
      <h1 style={{color: '#28728f', textAlign: 'center', paddingTop: '20px'}}>{t("Fat Rate List")}</h1>
      <div className='table-container'>
      <table ref={tableRef}>
          <thead>
            <tr>
              <th>SN.</th>
              <th>{t("Name")}</th>
              <th>{t("Rate")}</th>
            </tr>
          </thead>
          <tbody>
          {data.map((row, rowIndex) => (
              <tr key={rowIndex + 1}>
                <td>{rowIndex + 1}</td>
                <td
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) => handleCellEdit(rowIndex, 'name', e.target.innerText)}
                >
                  {row.name}
                </td>
                <td
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) => handleCellEdit(rowIndex, 'rate', e.target.innerText)}
                >
                  {row.rate}
                </td>
              </tr>
                ))}
          </tbody>
        </table>
        <button onClick={handleAddRow}>{t("Add Row")}</button>
      </div>
    </ViewChartDesign>
  );
};

export default GradeTracker;
